import React, { useState } from 'react';

import { useWalletClient, useAccount, useNetwork, usePublicClient } from 'wagmi';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import FormHelperText from '@mui/material/FormHelperText';
import LinkIcon from '@mui/icons-material/Link';
import LoadingButton from '@mui/lab/LoadingButton';
import BuildContractForm from './BuildContractForm';


const BuildDeployVerify = (props) => {
  const { contractTitle, buildContract, buildFields, githubLink, standardLink } = props;
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient()
  const { isDisconnected } = useAccount();
  const { chain: currentChain } = useNetwork();
  const [deployedChain, setDeployedChain] = useState('');
  const [abi, setAbi] = useState('');
  const [bytecode, setBytecode] = useState('');
  const [contractSrc, setContractSrc] = useState('');
  const [cid, setCid] = useState('');
  const [txhash, setTxhash] = useState('');
  const [address, setAddress] = useState('');

  const [isFetchingCompile, setIsFetchingCompile] = useState(false);
  const [isFetchingDeploy, setIsFetchingDeploy] = useState(false);
  const [isFetchingVerify, setIsFetchingVerify] = useState(false);
  const [isDeployComplete, setIsDeployComplete] = useState(false);

  const deployContract = async (e) => {
    e.preventDefault();
    setIsFetchingDeploy(true);
    setDeployedChain(currentChain.name);
    try {
      setIsDeployComplete(false);
      const [address] = await walletClient.getAddresses();
      const txHash = await walletClient.deployContract({
        abi,
        account: address,
        bytecode,
      });
      setTxhash(txHash);
      const receipt = await publicClient.waitForTransactionReceipt({ hash: txHash });
      setAddress(receipt.contractAddress);
      setIsDeployComplete(true);
    } finally {
      setIsFetchingDeploy(false);
    }
  };

  const verifyContract = async (e) => {
    e.preventDefault();
    setIsFetchingVerify(true);
    try {
      const response = await fetch('https://FakeWholeCache.chainnames.repl.co/verify-contract', {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        // credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
        },
        // redirect: "follow", // manual, *follow, error
        // referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(
          {
            "cid": cid,
            "address": address,
            "network": deployedChain.toLowerCase()
          }
        ), // body data type must match "Content-Type" header
      });
      const json = await response.json();
      console.log(json);
    } finally {
      setIsFetchingVerify(false);
    }
  };

  const isDeployDisabled = isDisconnected || !Boolean(bytecode) || !Boolean(abi) || isFetchingDeploy || isFetchingCompile;

  return (
    <Grid container spacing={3} item>
      <Grid item xs={12} md={8} lg={7} xl={6}>
        <BuildContractForm
          formTitle={contractTitle}
          build_contract={buildContract}
          build_fields={buildFields}
          isFetchingCompile={isFetchingCompile}
          setIsFetchingCompile={setIsFetchingCompile}
          bytecode={bytecode}
          setBytecode={setBytecode}
          abi={abi}
          setAbi={setAbi}
          contractSrc={contractSrc}
          setContractSrc={setContractSrc}
          setCid={setCid}
          isFetchingDeploy={isFetchingDeploy}
          currentChain={currentChain}
          deployContract={deployContract}
          isDeployDisabled={isDeployDisabled}
          githubLink={githubLink}
          standardLink={standardLink}
        />
        <Grid item xs={12} mt={5}>
        <Divider>
          Post-deployment tools
        </Divider>
        </Grid>
        <Grid item xs={12}>
          <Box mb={2} mt={2}>
            <Alert
              color="info"
              action={
                <LoadingButton variant="contained" size="small" loading={isFetchingVerify} disabled={!isDeployComplete} onClick={verifyContract}>
                  Verify contract { deployedChain ? ` on ${deployedChain}` : "" }
                </LoadingButton>
              }
            >
              Verify your contract on Etherscan
            </Alert>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField label="Deployed Transaction Hash" fullWidth variant='outlined' InputProps={{ readOnly: true }} value={txhash ? txhash : ""} placeholder="Deploy contract to see transaction hash" />
          {txhash ? <FormHelperText><a target="_blank" rel="noreferrer" href={`https://${(deployedChain || "").toLowerCase()}.etherscan.io/tx/${txhash || "none"}`}><LinkIcon/> Etherscan tx hash link</a></FormHelperText> : null}
        </Grid>
        <Grid item xs={12} mb={5} mt={1}>
          <TextField label="Deployed Contract Address" fullWidth variant='outlined' InputProps={{ readOnly: true }} value={address ? address : ""} placeholder="Deploy contract to see address" />
          {address ? <FormHelperText><a target="_blank" rel="noreferrer" href={`https://${(deployedChain || "").toLowerCase()}.etherscan.io/address/${address || "none"}`}><LinkIcon/> Etherscan contract link</a></FormHelperText> : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BuildDeployVerify;