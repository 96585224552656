import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const About = () => {
  return (
    <Grid container spacing={3} item>
      <Grid item xs={12} md={8} lg={7} xl={6}>
        <Typography variant="h6" mb={3}>Frequently Asked Questions</Typography>
        <Box mb={3}>
          <Typography variant='body1' sx={{fontWeight: "bold"}}>
            What is ChainRelay?
          </Typography>
          <Typography variant='body1'>
            This website provides you with the ability to quickly deploy common ethereum contract standards to popular EVM networks. The first contract standard we have made available is the NFT standard (ERC-721).
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant='body1' sx={{fontWeight: "bold"}}>
            Why did you make this website?
          </Typography>
          <Typography variant='body1'>
            I was recently building an NFT indexing service and found it frustrating that there was no quick and simple way to deploy NFT contracts with different specs to Ethereum in order to test that my indexing service worked. So this service was created in order to provide a way to deploy an NFT contract in under 2 minutes. We plan to support the most popular standards, like ERC-721, ERC-1155, and ERC-20. 
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant='body1' sx={{fontWeight: "bold"}}>
            Which EVM networks are supported?
          </Typography>
          <Typography variant='body1'>
            You may see the list of networks by connecting your wallet via the button above.
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant='body1' sx={{fontWeight: "bold"}}>
            What is "community created"?
          </Typography>
          <Typography variant='body1'>
            We are building the ability for anyone to upload a contract spec to ChainRelay, which will make those smart contracts available to users on this website to deploy to any EVM network. This will be a fun way for spec creators to get distribution for their contracts, and make it easy for users to experiment with new specs. You can see the current contracts available on our github.
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant='body1' sx={{fontWeight: "bold"}}>
            How will you make money?
          </Typography>
          <Typography variant='body1'>
            We are considering a few different options. Like taking a small fee on each deployment.
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant='body1' sx={{fontWeight: "bold"}}>
            How can I get in contact with you?
          </Typography>
          <Typography variant='body1'>
            Please send us an email at chain ~dot~ names at proton mail ~dot~ com! (obfuscated to avoid email scrapers)
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};


export default About;