import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const Community = () => {
  return (
    <Grid container spacing={3} item>
      <Grid item xs={12} md={8} lg={7} xl={6}>
        <Typography variant="h6" mb={1}>Community created contracts</Typography>
      </Grid>
    </Grid>
  );
};


export default Community;