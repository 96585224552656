import React, { useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import ConstructionIcon from '@mui/icons-material/Construction';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Link from '@mui/material/Link';


const ContractFormFields = ({build_fields, safeSetFieldValue, setContractSrc, contractSrc, fieldValues, isFetchingCompile, isFetchingDeploy, setAbi, setBytecode}) => {
  const onFieldChange = config_name => {
    return (event) => {
      event.preventDefault();
      if (contractSrc) {
        // used typed text instead so user sees input while typing
        setContractSrc("");
        setBytecode("");
        setAbi("");
      }
      safeSetFieldValue(config_name, event.target.value);
    };
  };
  return <>
    {build_fields.map((field_object) => {
      const {
        config_name,
        // eslint-disable-next-line no-unused-vars
        field_type, // ignore for now, all the same
        field_label,
        field_placeholder,
        // eslint-disable-next-line no-unused-vars
        validations, // ignore for now
      } = field_object;
      return <Box mb={2} key={config_name}>
        <TextField
          fullWidth
          label={field_label}
          key={config_name}
          placeholder={field_placeholder}
          value={fieldValues[config_name]}
          onChange={onFieldChange(config_name)}
          required
          disabled={isFetchingCompile || isFetchingDeploy}
        />
      </Box>;
    })}
  </>
};


const BuildContractForm = ({
  formTitle,
  build_contract,
  build_fields,
  isFetchingCompile,
  setIsFetchingCompile,
  bytecode,
  setBytecode,
  abi,
  setAbi,
  contractSrc,
  setContractSrc,
  setCid,
  isFetchingDeploy,
  currentChain,
  deployContract,
  isDeployDisabled,
  githubLink,
  standardLink,
}) => {
  const default_field_values = build_fields.reduce((reduction, field_config) => {
    reduction[field_config["config_name"]] = field_config["default_value"]
    return reduction;
  }, {});
  
  const [fieldValues, setFieldValues] = useState(default_field_values);
  const safeSetFieldValue = (k, v) => {
    setFieldValues(prevFields => ({
      ...prevFields,
      [k]: v,
    }))
  };
  const fieldNameToCurrentValue = () => {
    return build_fields.reduce((reduction, field_config) => {
      reduction[field_config["config_name"]] = fieldValues[field_config["config_name"]]
      return reduction;
    }, {});
  }
  const [typedContractSrc, setTypedContractSrc] = useState(build_contract(fieldNameToCurrentValue()));
  useEffect(() => {
    setTypedContractSrc(build_contract(fieldNameToCurrentValue()));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, Object.values(fieldValues));


  const compileContract = async (e) => {
    e.preventDefault();
    setIsFetchingCompile(true);
    try {
      const response = await fetch('https://FakeWholeCache.chainnames.repl.co/compile-contract', {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        // credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
        },
        // redirect: "follow", // manual, *follow, error
        // referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(fieldNameToCurrentValue()), // body data type must match "Content-Type" header
      });
      const json = await response.json();
      console.log(json);
      setBytecode(json.bytecode);
      setAbi(json.abi);
      setContractSrc(json.source);
      setCid(json.cid);
    } finally {
      setIsFetchingCompile(false)
    }
  };

  // returns true if any value is falsy
  const compileIsDisabled = Object.values(fieldValues).some(value => !value);

  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid item xs={12} p={0}>
        <Box mb={2}>
          <Typography variant="h6" mb={1}>{formTitle}</Typography>
          <Typography variant="body2" mb={2}>Make changes and see the code update below!</Typography>
          <Box mb={5}>
            <ButtonGroup variant="outlined" size="small" aria-label="text button group">
              <Button><Link underline='none' href={githubLink} target="_blank" rel='noopener'>Github</Link></Button>
              <Button><Link underline='none' href={standardLink} target="_blank" rel='noopener'>ERC Standard</Link></Button>
            </ButtonGroup>
          </Box>
        </Box>
        <ContractFormFields
          build_fields={build_fields}
          safeSetFieldValue={safeSetFieldValue}
          setContractSrc={setContractSrc}
          contractSrc={contractSrc}
          fieldValues={fieldValues}
          isFetchingCompile={isFetchingCompile}
          isFetchingDeploy={isFetchingDeploy}
          setAbi={setAbi}
          setBytecode={setBytecode}
        />
        <Box mb={2}>
          <LoadingButton startIcon={<ConstructionIcon />} variant="contained" fullWidth loading={isFetchingCompile} onClick={compileContract} disabled={compileIsDisabled || isFetchingCompile}>
            Build contract
          </LoadingButton>
        </Box>
        <Box mb={2}>
          <LoadingButton variant="contained" fullWidth startIcon={<RocketLaunchIcon />} loading={isFetchingDeploy} onClick={deployContract} disabled={isDeployDisabled}>
            Deploy contract{ Boolean(currentChain) ? ` to ${currentChain.name}` : "" }
          </LoadingButton>
        </Box>
      </Grid>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}>
            <Tab label="Contract code" value="1" />
            <Tab label="ABI" value="2" />
            <Tab label="Bytecode" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <TextField fullWidth multiline InputProps={{ readOnly: true }} value={contractSrc ? contractSrc : typedContractSrc} maxRows={20} placeholder="Click build to populate the contract source code." variant="outlined" />
        </TabPanel>
        <TabPanel value="2">
          <TextField fullWidth multiline InputProps={{ readOnly: true }} value={abi ? JSON.stringify(abi, null, 2) : ""} maxRows={20} placeholder="Click build to populate the ABI." variant="outlined" />
        </TabPanel>
        <TabPanel value="3">
          <TextField fullWidth multiline InputProps={{ readOnly: true }} value={bytecode} maxRows={20} sx={{width: '100%'}} placeholder="Click build to populate the bytecode." variant="outlined" />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default BuildContractForm;