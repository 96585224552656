import './App.css';
import '@rainbow-me/rainbowkit/styles.css';

import React, { useState } from 'react';
import { Routes, Route, Outlet, useLocation, useNavigate, Navigate } from "react-router-dom";
import merge from 'lodash.merge';

import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import {
  getDefaultWallets,
  RainbowKitProvider,
  ConnectButton,
  lightTheme,
} from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import {
  goerli,
  sepolia,
  mainnet,
  polygon,
  optimism,
  arbitrum,
  zora,
} from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import BuildDeployVerify from "./components/BuildDeployVerify";
import Community from './components/Community';
import About from './components/About';
import Token from './components/Token';
import NoMatch from './components/NoMatch';
import build_contract_nft from "./contracts/ethereum-contracts/ERC-721/default.js";
import build_fields_nft from "./contracts/ethereum-contracts/ERC-721/default.json";


const Layout = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  let location = useLocation();
  let navigate = useNavigate();

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton
            selected={location.pathname === "/faq"}
            onClick={(e) => {
              e.preventDefault();
              navigate("/faq");
            }}
          >
            <ListItemText primary="FAQ" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton
            selected={location.pathname === "/nft"}
            onClick={(e) => {
              e.preventDefault();
              navigate("/nft");
            }}
          >
            <ListItemText primary="NFT (ERC-721)" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            selected={location.pathname === "/token"}
            onClick={(e) => {
              e.preventDefault();
              navigate("/token");
            }}
          >
            <ListItemText primary="Token (ERC-20)" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton
            selected={location.pathname === "/community"}
            onClick={(e) => {
              e.preventDefault();
              navigate("/community");
            }}
          >
            <ListItemText primary="Commmunity created" />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  const drawerWidth = 250;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          bgcolor: grey[900]
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            ChainRelay
          </Typography>
          <ConnectButton label="Connect wallet" accountStatus={{ smallScreen: "avatar", largeScreen: "avatar" }} />
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}

const App = () => {
  const { chains, publicClient } = configureChains(
    [goerli, sepolia, mainnet, polygon, optimism, arbitrum, zora],
    [publicProvider()]
  );
  
  const { connectors } = getDefaultWallets({
    appName: "ChainRelay",
    projectId: 'd822ee65b6a87b59b95de68307465b3c',
    chains
  });

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient
  });

  const myTheme = merge(lightTheme({borderRadius: 'small'}), {
    fonts: {
      body: "Roboto, Helvetica, Arial, sans-serif"
    },
  });

  return (
    <>
      <CssBaseline />
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider chains={chains} theme={myTheme}>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Navigate to="/nft" replace />} />
              <Route path="faq" element={<About />} />
              <Route path="nft" element={
                  <BuildDeployVerify
                    contractTitle="NFT (ERC-721)"
                    buildContract={build_contract_nft}
                    buildFields={build_fields_nft}
                    githubLink="https://github.com/chain-names/ethereum-contracts/tree/main/ERC-721"
                    standardLink="https://eips.ethereum.org/EIPS/eip-721"
                  />
                }
              />
              <Route path="token" element={<Token />} />
              <Route path="community" element={<Community />} />
              {/* Using path="*"" means "match anything" */}
              <Route path="*" element={<NoMatch />} />
            </Route>
          </Routes>
        </RainbowKitProvider>
      </WagmiConfig>
    </>
  );
}

export default App;
